import React, { useState } from 'react'
import { SimpleScopesCreator } from './SimpleScopesCreator'
import { isUnknownEntityNode, PathScope, pathScopesToScopes, Scope, StructureNode } from '../../services/Dtos'
import { MasterdataAssignment } from './masterdata-assignment/MasterdataAssignment'

function isSimplePolicy(scopes: PathScope[]): boolean {
  const scopesWithLocationOnly = scopes.filter(
    scope => scope.exclude.length === 0 && scope.include.length === 1 && scope.include[0].type === 'locationId'
  ).length
  return scopesWithLocationOnly === scopes.length
}

function extractLocationIds(scopes: PathScope[]): string[] {
  return scopes.map(scope => scope.include[0].id)
}

type Props = {
  pathScopes: PathScope[] | undefined
  masterDataHierarchy: StructureNode[]
  onComplete: (scope: Scope[]) => void
  saveFunction: (scope: Scope[]) => Promise<Scope[]>
  readOnly: boolean
}

export const AccessConfigurator = ({ pathScopes, masterDataHierarchy, onComplete, saveFunction, readOnly }: Props) => {
  const [useSimpleView, setUseSimpleView] = useState(true)
  const [errorMessage, setErrorMessage] = useState('')
  const [isSubmitting, setIsSubmitting] = useState(false)

  const onSubmit = (scopes: Scope[]) => {
    setIsSubmitting(true)
    saveFunction(scopes)
      .then(onComplete)
      .catch((err: any) => {
        setIsSubmitting(false)
        setErrorMessage(err.message ?? 'Unknown error')
      })
  }

  const unknownEntities = (pathScopes ?? []).flatMap(value =>
    value.include.filter(isUnknownEntityNode).concat(value.exclude.filter(isUnknownEntityNode))
  )

  const simplePolicy = isSimplePolicy(pathScopes ?? [])

  return (
    <React.Fragment>
      {!errorMessage && unknownEntities.length > 0 && (
        <div className="alert alert-danger">
          ⚠️ Unknown and inactive scopes{!readOnly && ' will be removed on save'}:
          <ul>
            {unknownEntities.map(v => (
              <li key={v.id}>{v.display}</li>
            ))}
          </ul>
        </div>
      )}
      {errorMessage && <div className="alert alert-danger">Unable to save new scope ({errorMessage})</div>}
      {useSimpleView && simplePolicy ? (
        <SimpleScopesCreator
          readOnly={readOnly}
          isSubmitting={isSubmitting}
          initialLocationIds={extractLocationIds(pathScopes ?? [])}
          onComplete={onSubmit}
          onAdvanced={() => setUseSimpleView(false)}
        />
      ) : (
        <MasterdataAssignment
          readOnly={readOnly}
          isSubmitting={isSubmitting}
          initialScopes={pathScopes}
          hierarchyData={masterDataHierarchy}
          onComplete={pathScopes => onSubmit(pathScopesToScopes(pathScopes))}
          onSimple={() => setUseSimpleView(true)}
          simplePolicy={simplePolicy}
        />
      )}
    </React.Fragment>
  )
}
