// Types which are owned by the API application (Data Transfer Objects)

export type ResourceType =
  | 'machineType'
  | 'serialNumber'
  | 'installationId'
  | 'country'
  | 'region'
  | 'state'
  | 'storeReference'
  | 'chainId'
  | 'subChainId'
  | 'locationId'
  | 'clearingHouse'
  | 'collectionType'

export type UnknownResourceType =
  | 'UNKNOWN_machineType'
  | 'UNKNOWN_serialNumber'
  | 'UNKNOWN_installationId'
  | 'UNKNOWN_country'
  | 'UNKNOWN_region'
  | 'UNKNOWN_state'
  | 'UNKNOWN_storeReference'
  | 'UNKNOWN_chainId'
  | 'UNKNOWN_subChainId'
  | 'UNKNOWN_locationId'
  | 'UNKNOWN_clearingHouse'
  | 'UNKNOWN_collectionType'

export type StructureNode = {
  type: ResourceType | UnknownResourceType
  display: string
  id: string
  path: string
  children: StructureNode[]
}

export type PathScope = {
  include: StructureNode[]
  exclude: StructureNode[]
}

export type Entity = {
  id: string
  type: ResourceType
}

export type Scope = {
  include: Entity[]
  exclude: Entity[]
}

export const pathScopesToScopes = (pathScopes: PathScope[]): Scope[] => {
  return pathScopes.map(pathScope => {
    return {
      include: pathScope.include
        .filter(node => !node.type.startsWith('UNKNOWN_'))
        .map(node => {
          return { type: node.type as ResourceType, id: node.id }
        }),
      exclude: pathScope.exclude
        .filter(node => !node.type.startsWith('UNKNOWN_'))
        .map(node => {
          return { type: node.type as ResourceType, id: node.id }
        })
    }
  })
}

export const scopesToPathScopes = (scopes: Scope[], structureNodes: StructureNode[]): PathScope[] => {
  return scopes.map(scope => ({
    include: scope.include.map(entity => findInNodes(entity, structureNodes) || getUnknownEntityNode(entity)),
    exclude: scope.exclude.map(entity => findInNodes(entity, structureNodes) || getUnknownEntityNode(entity))
  }))
}

export function getUnknownEntityNode(entity: Entity): StructureNode {
  return {
    type: `UNKNOWN_${entity.type}`,
    id: `${entity.id}`,
    children: [],
    path: '',
    display: `${entity.type}:${entity.id}`
  }
}

export function isUnknownEntityNode(node: StructureNode) {
  return node.type.startsWith('UNKNOWN_')
}

export function getFullPathOfNode(node: StructureNode) {
  const prefix = node.path ? `${node.path};` : ''
  return `${prefix}${node.type}:${node.id}`
}

export function isAncestorOf(ancestor: StructureNode, child: StructureNode) {
  return child.path.startsWith(getFullPathOfNode(ancestor))
}

const findInNodes = (target: Entity, nodes: StructureNode[]): StructureNode | null => {
  for (let i = 0; i < nodes.length; i++) {
    const node = nodes[i]
    if (target.id === node.id && target.type === node.type) {
      return node
    }
    const foundChild = findInNodes(target, node.children)
    if (foundChild) {
      return foundChild
    }
  }
  return null
}

export type AddUserForLicenseDto = {
  firstName: string
  lastName: string
  role: string
  email: string
}

export type CreateLicenseDto = {
  productName: string
  scopes?: Scope[]
  from: Date
  to?: Date
  orderedBy?: string
  ticketNo?: string
  invoicedFrom?: Date
}

export type TransitionToNewProduct = {
  orderedBy?: string | null
  ticketNo?: string | null
  targetProduct: string
}

export type CreateAccountRequestData = {
  businessUnitId: string
  externalId: string
  name: string
  description?: string
  scopes?: Scope[]
}

export type BusinessUnit = {
  id: string
  name: string
  countries: string[]
  ifsNumber?: string
  invoiceable: boolean
}

export type AccountUserRegisteredFields = {
  businessUnitId: string
  externalId: string
  name: string
  description?: string
  policyId: string
  scopes?: Scope[]
  authorId: string
  authorName: string
}

export type AccountDto = AccountUserRegisteredFields & {
  id: string
  accountState: 'ACTIVE' | 'DEACTIVATED'
  created: string
  externalId?: string
}

export type ViewableUser = {
  id: string
  userId: string
  firstName: string
  lastName: string
  email: string
  role: string
}

export type UserLicense = {
  accountId: string
  accountName: string
  productName: string
  role: string
  activated: Date
  expires: Date | null
}

export type UserDetails = {
  user: {
    id: string
    firstName: string
    lastName: string
    email: string
  }
  licenses: UserLicense[]
}

export type License = {
  id: string
  accountId: string
  productName: string
  scopes?: Scope[]
  from: string
  to?: string | null
  orderedBy?: string | null
  ticketNo?: string | null
  invoicedFrom?: string | null
  authorName: string
  authorId: string
  deactivated: string | null
}

export type FullLicense = License & {
  aliases: string[]
  users: ViewableUser[]
}

export type AccountCreationResponse = {
  businessUnitId: string
  description: string
  externalId: string
  id: string
  name: string
}

export type FullAccountDto = {
  account: AccountDto
  licenses: FullLicense[]
}

export type DeactivatedAccount = {
  accountId: string
}

export type ProductWithRolesDto = {
  product: string
  invoiceable: boolean
  availableForMarketAdmin: boolean
  disabled: boolean
  roles: string[]
}

export type AggregatedStatisticsPrBusinessUnit = {
  [key: string]: {
    [key: string]: {
      totalLicenses: number
      invoicedLicenses: number
      totalRvms: number
      invoicedRvms: number
    }
  }
}

export type ReportEntry = {
  businessUnitId: string
  id: number
  invoiceRows: number
  reportTimestamp: string
  created: string
}

export type AccountDetailsDto = {
  accountDetails: [{ id: string; name: string }]
}
