import React from 'react'
import styled from 'styled-components'
import { getFullPathOfNode, PathScope, ResourceType, UnknownResourceType } from '../../../services/Dtos'

const AllowLi = styled.li`
  color: green;
`

const PaddedUl = styled.ul`
  padding-left: 20px;
`

const DenyLi = styled.li`
  color: red;
`

// eslint-disable-next-line no-unused-vars
const typeToDisplayMap: { [key in ResourceType | UnknownResourceType]: string } = {
  clearingHouse: 'Clearing House',
  collectionType: 'Collection Type',
  machineType: 'Machine Type',
  region: 'Region',
  serialNumber: 'Serial number',
  state: 'State',
  storeReference: 'Store',
  locationId: 'Store',
  chainId: 'Organization',
  subChainId: 'Sub Organization',
  country: 'Country',
  installationId: 'Machine',
  UNKNOWN_clearingHouse: 'Unknown clearing house',
  UNKNOWN_collectionType: 'Unknown collection type',
  UNKNOWN_machineType: 'Unknown machine type',
  UNKNOWN_region: 'Unknown region',
  UNKNOWN_serialNumber: 'Inactive serial number',
  UNKNOWN_state: 'Unknown state',
  UNKNOWN_storeReference: 'Inactive store',
  UNKNOWN_locationId: 'Inactive store',
  UNKNOWN_chainId: 'Inactive organization',
  UNKNOWN_subChainId: 'Inactive suborganization',
  UNKNOWN_country: 'Unknown country',
  UNKNOWN_installationId: 'Inactive machine'
}

type Props = {
  scopes: PathScope[]
}

export const ScopeSummary = ({ scopes }: Props) => {
  return (
    <div>
      <ul>
        <li>{scopes.length > 0 ? 'Allowing:' : <i>No configured access</i>}</li>
        {scopes
          .map(
            scope =>
              [
                scope,
                scope.include
                  .map(node => `${typeToDisplayMap[node.type]}: ${node.display}`)
                  .filter(n => n !== undefined)
                  .join(', ')
              ] as [PathScope, string]
          )
          .sort((a, b) => (a[1] > b[1] ? 1 : -1))
          .map(([scope, displayValue]) => {
            return (
              <PaddedUl key={scope.include.map(getFullPathOfNode).join('_')}>
                <AllowLi>{displayValue}</AllowLi>
                {scope.exclude.length > 0 ? (
                  <PaddedUl>
                    <li>Except:</li>
                    <PaddedUl>
                      {scope.exclude.map(exclude => (
                        <DenyLi key={getFullPathOfNode(exclude)}>
                          {`${typeToDisplayMap[exclude.type]}: ${exclude.display}` ?? 'Invalid'}
                        </DenyLi>
                      ))}
                    </PaddedUl>
                  </PaddedUl>
                ) : null}
              </PaddedUl>
            )
          })}
      </ul>
    </div>
  )
}
